.search-tip {
  align-items: baseline;
}

.search-tip > svg {
  flex-shrink: 0;
}

.search-tip > p {
  flex-grow: 1;
}
