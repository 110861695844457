.multiselect-options {
  border: solid 1px var(--search-color-grey-400);
  box-shadow: none;
  max-height: 22rem;
  margin-bottom: 0!important;
  overflow-y: scroll;
}

@media only screen and (min-width: 640px) {
  .multiselect-options {
    height: 22rem;
  }
}
