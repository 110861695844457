table {
  border-collapse: collapse;
  border-spacing: 0;
}

.container {
  margin: 0 auto;
  padding: 0 0.75rem;
}

.container-narrow {
  max-width: 54rem;
  width: 100%;
}

.container-medium {
  max-width: 1200px;
}


/*
    Accessibility utilities
*/
.visually-hidden {
  /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

/*
  Typography and Elements
*/

a {
  color: var(--search-color-blue-400);
  cursor: pointer;
  text-decoration: none;
  font-weight: inherit;
}

a:not([class], [id]),
.underline,
.underline__hover:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

a:not([class], [id]):hover,
.underline__hover-thick:hover {
  text-decoration-thickness: 2px;
}

*::selection {
  background: var(--search-color-blue-300);
}

img {
  height: auto;
  max-width: 100%;
}

dt {
  font-weight: 600;
}

dd {
  margin: 0;
}

.table-compact table {
  border: none;
}

.table-compact td,
.table-compact th {
  padding: 0.25rem 0.5rem;
  font-size: 90%;
}

blockquote {
  font-family: "PT Serif", serif;
  font-size: 1.5rem;
  padding-left: 1.5rem;
  border-left: solid 0.5rem var(--search-color-blue-400);
  margin: 2rem 0;
  color: var(--search-color-blue-400);
  font-style: italic;
}

sup {
  font-size: 0.6em;
  vertical-align: top;
}

.text-small {
  font-size: 0.85rem;
}

.text-grey {
  color: var(--search-color-grey-600);
}

.text-grey__light {
  color: var(--ds-color-neutral-300);
}

small[id*="-description"] {
  display: block;
}

label {
  display: block;
}

.fieldset-label {
  font-weight: 700;
}

input,
button,
textarea {
  font-size: 1rem;
  font-family: "Noto Sans", sans-serif;
  color: #262626;
}

input[type="text"],
input[type="url"],
input[type="search"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="number"],
[role="textbox"] {
  background: white;
  border: solid 1px var(--search-color-grey-400);
  border-radius: 4px;
  box-shadow: inset 0 1px 4px var(--search-color-grey-300);
  font-size: 1rem;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  padding: 0.5rem 0.75rem;
  width: 100%;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]:disabled,
input[type="search"]:read-only,
input[type="email"]:disabled,
input[type="email"]:read-only,
input[type="tel"]:disabled,
input[type="tel"]:read-only,
input[type="text"]:disabled,
input[type="text"]:read-only,
input[type="url"]:disabled,
input[type="url"]:read-only,
input[type="number"]:disabled,
input[type="number"]:read-only {
  background: var(--search-color-grey-200);
  border-color: var(--search-color-grey-400);
  color: var(--search-color-grey-600);
  cursor: not-allowed;
}

.help-text {
  margin: 0;
}

fieldset.field-valid input[type="text"] {
  border-color: #05A657;
}

fieldset.field-valid .help-text {
  color: #05A657;
  font-size: 0.9rem;
}

fieldset.field-error input[type="text"] {
  border-color: #ED5D47;
}

fieldset.field-error .help-text {
  color: #ED5D47;
  font-size: 0.9rem;
}

fieldset.field-warning input[type="text"] {
  border-color: #E77504;
}

fieldset.field-warning .help-text {
  color: #E77504;
  font-size: 0.9rem;
}

textarea {
  display: block;
  padding: 1rem;
  margin: 1rem 0;
  border: solid 1px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  max-width: 100%;
}

.form-label {
  display: block;
  padding-bottom: 0.15rem;
  line-height: 1.4;
}

.form-group {
  margin-bottom: 1.5rem;
}

.button {
  display: inline-block;
  margin: 0;
  padding: 0.5rem 1rem;
  background: var(--search-color-blue-400);
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  border-bottom: 3px solid var(--search-color-blue-500);
  cursor: pointer;
  text-decoration: none;
  line-height: inherit;
}

.button:active {
  color: #FAFAFA;
  background: var(--search-color-blue-500);
  border-color: #00274C;
}

.button:active,
.button:hover {
  text-decoration: none;
}

.button:hover {
  cursor: pointer;
  background: #1060aa;
  border-color: #0e5392;
  color: white;
}

.button-secondary {
  display: inline-block;
  margin: 0;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(18, 109, 193, 0.4);
  background: #F2F9FF;
  border-radius: 4px;
  color: var(--search-color-blue-400);
  line-height: inherit;
}

.button-secondary:hover {
  cursor: pointer;
}

.btn {
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
}

.btn[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn--primary,
.btn--secondary {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
}

.btn--primary {
  background: var(--search-color-blue-400);
  color: white;
  border: 0;
  border-bottom: var(--search-color-blue-500);
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.btn--primary:focus {
  background: var(--search-color-blue-400);
}

.btn--primary:hover {
  cursor: pointer;
  background: var(--search-color-blue-500);
  border-color: var(--color-blue-400);
  color: white;
}

.btn--secondary {
  color: var(--search-color-grey-600);
  border: solid 1px #818181;
  background: white;
}

.btn--secondary:disabled {
  border-color: var(--search-color-grey-500);
  color: var(--search-color-grey-500);
}

.btn--secondary:not(:disabled):hover {
  border-color: var(--search-color-grey-600);
  box-shadow:
    0 0 0 2px #fff,
    0 0 0 3px var(--search-color-grey-600);
}

.btn--secondary--active {
  background: var(--search-color-blue-200);
  border-color: var(--search-color-blue-400);
  color: var(--search-color-blue-500);
}

.btn--tertiary {
  color: var(--search-color-grey-600);
  text-decoration: underline;
}

.btn--tertiary:hover {
  text-decoration-thickness: 2px;
}

.btn--small {
  font-size: 0.875rem;
}

.btn--primary.btn--small,
.btn--secondary.btn--small {
  padding: 0.25rem 0.5rem;
}

.button[disabled] {
  opacity: 0.5;
}

.button[disabled]:hover,
.button[disabled]:active {
  cursor: not-allowed;
}

.button-light {
  background: transparent;
  color: var(--search-color-grey-600);
  border: solid 1px #CCC;
  border-radius: 4px;
  padding: 0.4rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
}

.button-small {
  padding: 0.4rem 0.8rem;
}

.button-radio {
  margin: 1rem 0;
  padding: 1rem;
  border: solid 2px #CCC;
  background: #FAFAFA;
  border-radius: 4px;
  cursor: pointer;
}

.button-link {
  border: none;
  display: inline-block;
  margin: 0;
  color: var(--search-color-blue-400);
  background: none;
  font-size: 1rem;
  padding: 0;
  cursor: pointer;
  border-radius: 0;
  line-height: inherit;
  line-height: inherit;
}

.button-link-light {
  background: none;
  border: none;
  padding: 0;
  color: var(--search-color-grey-600);
  line-height: inherit;
  text-align: left;
  border-bottom: solid 1px #CCC;
  border-radius: 0;
}

.button-link-light:hover {
  color: var(--search-color-grey-600);
  cursor: pointer;
  border-color: var(--search-color-grey-600);
}

.button-small {
  font-size: 0.8rem;
  padding: 0.2rem 0.6rem;
}

.search-header {
  padding: 0.8rem 0 !important;
}

.search-header svg {
  height: 26px !important;
}

.no-margin {
  margin: 0;
}

.white-text {
  color: white;
}

.right {
  float: right;
}

.center-text {
  text-align: center;
}

.offscreen {
  position: absolute;
  left: -999em;
}

.skip-to-id {
  position: fixed;
  left: -999px;
}

.skip-to-id:focus {
  left: 0.5rem;
  top: 0.5rem;
  background: var(--search-color-blue-500);
  text-decoration: underline;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

summary {
  cursor: pointer;
  display: flex;
  font-weight: 600;
  list-style: none;
  justify-content: space-between;
  padding: 0.75rem;
}

summary::-webkit-details-marker {
  display: none;
}

summary:hover {
  text-decoration: underline;
}

summary svg {
  color: var(--ds-color-neutral-300);
}

details:not([open]) > summary svg.expand_less,
details[open] > summary svg.expand_more {
  display: none!important;
}

.table {
  width: 100%;
  border: solid 1px #CCC;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.table thead {
  background: #F2F2F2;
  border-bottom: solid 2px #E5E5E5;
}

.table td,
.table th {
  padding: 0.75rem 1rem;
}

.table th {
  text-align: left;
  font-weight: 600;
}

.table tr {
  border-bottom: solid 1px #CCC;
}

[data-inner-container] {
  max-width: 1200px;
}

.card {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.04);
  border-bottom: solid 2px #E5E5E5;
  padding: 1rem;
  background: white;
}

.card-light {
  background: #FAFAFA;
}

/*
  Multiple results container // aka Bento box
*/
.results-container {
  width: 100%;
}

.results-summary-container {
  align-items: baseline;
  box-shadow: none!important;
  flex-wrap: wrap;
  gap: 0.25rem 1rem!important;
  justify-content: space-between;
  padding: 0.5rem 1.25rem;
}

.results-summary-container > p {
  flex-grow: 1;
}

.parser-message {
  align-items: baseline;
  gap: 0.5rem;
}

.parser-message p,
.results-message p {
  margin: .25em 0;
}

.details-container>svg {
  top: .35em;
  position: relative;
}

.details-message>strong {
  font-weight: 600;
}

.details-message>a {
  text-decoration: underline;
}

.pagination-container {
  padding: 0.75rem 1rem;
  background: white;
  background: #FAFAFA;
  margin-bottom: 3rem;
  margin-top: 1rem;
  border-radius: 4px;
}

.pagination-container .button-secondary,
.pagination-container .results-summary {
  margin-right: 1rem;
}

@media (max-width: 820px) {
  .pagination-container .results-summary {
    padding: 0;
    display: block;
  }
}

.results-summary {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

.records-summary {
  padding: 0 1rem;
}

.filters-container {
  margin-bottom: 1rem;
}

.filters-heading {
  padding: 0.75rem 1rem;
  margin: 0;
  background: #FAFAFA;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
}

.filter-group-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.filter-group {
  background: #FAFAFA;
  margin: 0;
  padding: 0;
}

.filter-group-heading {
  margin: 0;
  background: #FAFAFA;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
}

.no-filters-available {
  border-radius: 4px;
  background: #FAFAFA;
  padding: 1rem;
}

.filter-group-toggle-show-button {
  appearance: none;
  margin: 0;
  padding: 0.75rem 1rem;
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
}

.filter-group-toggle-show-button:hover .filter-group-heading {
  text-decoration: underline;
}

.filter-group-toggle-show-button .icon {
  width: 0.9rem;
  height: 0.9rem;
}

.filter-group-toggle-show-button .icon g {
  fill: var(--search-color-grey-600);
}

.filter-item,
.active-filter-item {
  padding: 0;
  margin: 0;
}

.filter-item {
  margin-bottom: 0.25rem;
}

.filter-button {
  background: none;
  border: none;
  text-align: left;
  padding: 0;
  width: 100%;
  padding: 0.15rem 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.filter-button:hover .filter-value {
  text-decoration: underline;
}

.filter-value {
  margin-right: 0.5rem;
  word-break: break-word;
  color: var(--search-color-blue-400);
}

.filter-item-active .filter-value,
.filter-item-active .filter-count {
  font-weight: 600;
}

.active-filter-item {
  margin: 0 1rem;
  padding-bottom: 0.5rem;
}

.active-filter-button .icon g {
  fill: #E2F4EB;
}

.active-filter-button {
  display: flex;
  margin: 0;
  width: 100%;
  padding: 0.75rem 1rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none;
  background: #E2F4EB;
  border: solid 1px rgba(5, 124, 66, 0.4);
  border-radius: 4px;
}

.active-filter-button .icon {
  width: 0.8rem;
  height: 0.8rem;
}

.active-filter-button .icon g {
  fill: #057C42;
}

.active-filter-button:hover .active-filter-button-text {
  text-decoration: underline;
}

.active-filter-button-text {
  font-size: 0.9rem;
  color: #057C42;
  text-align: left;
  max-width: 90%;
}

.filter-group-checkbox {
  display: flex;
  cursor: pointer;
  margin-bottom: 0;
  padding: 0 1rem 0.5rem;
}

.filter-group-checkbox:hover {
  text-decoration: underline;
}

.filter-checkbox {
  display: inline-block;
  width: 1.5rem;
  margin: 0;
  flex-shrink: 0;
}

.filter-checkbox-checked {
  color: var(--search-color-blue-400);
}

.filter-group-multiselect {
  border-top: solid 1px #E5E5E5;
}

.flex-container {
  display: block;
}

@media (min-width: 980px) {
  .flex-container {
    display: flex;
  }
}

.side-container {
  width: 100%;
  margin-right: 1rem;
}

@media (min-width: 980px) {
  .side-container {
    width: 25.5rem;
  }
}

.page {
  display: inherit;
  margin-top: 1rem;
  padding: 2rem;
}

@media (max-width: 820px) {
  .page {
    padding: 1.5rem;
  }
}

/*
  Record
*/
.record {
  margin: 0.75rem 0;
  padding: 0;
}

.record-container {
  padding: 0.75rem 1rem;
}

@media screen and (min-width: 820px) {
  .record-container {
    padding: 0.9rem 1.5rem;
    padding-left: 3rem;
  }
}

.record-title {
  font-weight: 600;
  font-size: 1.2rem;
}

.record-title .icon {
  width: 0.85rem;
  color: var(--search-color-grey-600);
}

.vernacular {
  color: #6e6e6e;
}

.vernacular-record-title {
  display: block;
}

.record-title-link {
  text-decoration: underline;
  text-decoration-color: rgba(18, 109, 193, 0.4);
  text-decoration-thickness: 2px;
  text-underline-offset: 2px;
}

.record-title-link:hover {
  background: var(--search-color-blue-200);
}

.record-title-link+.icon {
  margin-left: 0.5rem;
}

.record-field-list {
  margin-top: 0rem;
}

.record-field-list .record-field {
  margin-top: 0.25rem;
}

@media (min-width: 820px) {
  .record-field-list {
    display: table;
    width: 100%;
  }

  .record-field-list .record-field {
    display: table-row;
    margin-top: 0;
  }

  .record-field-list .record-field-name {
    display: table-cell;
    width: 160px;
    padding-right: 1rem;
    color: var(--search-color-grey-600);
    font-weight: 400;
  }

  .record-field-list .record-field-name:after {
    content: none;
  }

  .record-field-list .record-field-value {
    display: table-cell;
    word-break: break-word;
  }
}

.record-field-name {
  vertical-align: top;
  padding-right: 0.25rem;
  color: var(--search-color-grey-600);
  display: block;
}

@media (max-width: 820px) {
  .record-field-name {
    font-size: 0.82rem;
    font-weight: 400;
    color: var(--search-color-grey-600);
  }
}

.record-field-value {
  display: block;
}

.record-field-value a {
  text-decoration: underline;
}

.record-field-description-has-inner-html *:first-child {
  margin-top: 0;
}

.record-field-description-has-inner-html *:last-child {
  margin-bottom: 0;
}

/*
  Full Record Specific
*/
.full-record-container {
  margin-bottom: 1.5rem;
  order: 2;
}

.full-record-container .record-field-list {
  margin-bottom: 1rem;
}

.full-record-container .record-field-list .record-field {
  margin-top: 0.5rem;
}

.full-record-container .record-field-list .record-field dt,
.full-record-container .record-field-list .record-field dd {
  padding: 0.25rem 0;
}

@media (max-width: 820px) {

  .full-record-container .record-field-list .record-field dt,
  .full-record-container .record-field-list .record-field dd {
    padding: 0;
    margin-top: 0;
  }
}

.full-record-header {
  display: flex;
  background: var(--search-color-blue-200);
  padding: 1rem 2rem;
  min-height: 3.68rem;
  border-radius: 4px 4px 0 0;
}

.full-record-header p {
  margin: 0;
  color: var(--search-color-blue-400);
}

@media (min-width: 820px) {
  .full-record-title-and-actions-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.full-record-format {
  color: var(--search-color-blue-500);
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
}

.full-record-format .icon {
  color: inherit;
  margin-right: 0.5rem;
}

.full-record-title {
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 400;
  margin-right: 1.5rem;
}

@media (min-width: 600px) {
  .full-record-title {
    font-size: 1.75rem;
  }
}

.full-record__record-info {
  font-size: 1rem;
  margin: 0;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

/*
  Record special fields
*/
.record-field-value .icon {
  display: inline-block;
  margin-right: 0.3rem;
}

.record-field-value .icon g {
  fill: #6E6E6E;
}

.record-field-value-list {
  display: inline-block;
  margin: 0;
  padding: 0;
  padding-right: 0.5rem;
  list-style: none;
}

.record-field-value-list-item {
  display: block;
  margin: 0;
  padding: 0;
  padding-bottom: 0.25rem;
}

.record-field-uid-academic_discipline .icon {
  width: 0.75rem;
  height: 0.65rem;
  margin: 0 0.25rem;
}

.record-field-value-link {
  text-decoration: underline;
}

.record-field-uid-format .record-field-value-item {
  display: inline-block;
  font-weight: 600;
  margin-right: 1rem;
}

.record-field-uid-format .record-field-value-item:after {
  content: '' !important;
}

.record-field-uid-format .record-field-value-item .icon {
  margin-bottom: -2px;
}

@media (min-width: 820px) {
  .record-field-uid-format .record-field-name {
    padding-bottom: 0.25rem;
    margin-right: 0.5rem;
  }
}

.full-record__description {
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.full-record-page-container {
  margin-top: 1rem;
}

@media (min-width: 820px) {
  .full-record-page-container {
    display: flex;
    flex-direction: column;
  }
}

.record-person__header-has-picture {
  display: flex;
  align-items: center;
}

.record-person__profile-picture {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: cover;
  object-position: top right;
}

.loading-record-text {
  color: var(--search-color-blue-500);
}

.resource-access-container {
  padding: 0.75rem 1rem;
  align-items: center;
  border-top: solid 1px #E5E5E5;
  border-radius: 0 0 4px 4px;
}

@media screen and (min-width: 820px) {
  .resource-access-container {
    padding: 0.75rem 1.5rem;
    padding-left: 3rem;
  }
}

.resource-access-container a {
  text-decoration: underline;
}

.resource-access-container>*:not(:last-child) {
  margin-bottom: 0.5rem;
}

.intent__informational {
  color: var(--search-color-blue-300);
}

.intent__success {
  color: var(--search-color-green-300);
}

.intent__warning {
  color: var(--search-color-orange-300);
}

.intent__error {
  color: var(--search-color-red-300);
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -100vw 0;
  }

  100% {
    background-position: 100vw 0;
  }
}

.placeholder-container {
  margin-bottom: 1rem;
}

.placeholder {
  animation: placeHolderShimmer 25s linear infinite forwards;
  background: linear-gradient(to left, rgba(229, 229, 229, 0.6) 20%, #E5E5E5 50%, rgba(229, 229, 229, 0.6) 80%);
  vertical-align: middle;
  height: 0.6rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

.access-placeholder-container {
  width: 100%;
}

.access-placeholder-container .placeholder {
  margin-bottom: 0;
}

.placeholder-title {
  height: 1rem;
  max-width: 600px;
  margin-bottom: 2rem;
  background: linear-gradient(to left, #F2F9FF 20%, var(--search-color-blue-200) 50%, #F2F9FF 80%);
}

.placeholder-line {
  max-width: 22rem;
}

.placeholder-line-alt {
  max-width: 18rem;
}

.placeholder-inline {
  display: inline-block;
  width: 100%;
  max-width: 20rem;
  margin-right: 1rem;
}

.placeholder-access {
  max-width: 12rem;
  background: linear-gradient(to left, #F2F9FF 20%, var(--search-color-blue-200) 50%, #F2F9FF 80%);
}

.record-preview {
  padding: 0.75rem 1rem;
  border-bottom: solid 1px var(--search-color-grey-400);
}

.record-preview-title-link {
  margin-right: 0.25rem;
}

.record-preview-published-date {
  color: var(--search-color-grey-600);
}

.record-preview-format-and-author {
  margin: 0;
}

.record-preview-about {
  margin: 0;
  color: var(--search-color-grey-600);
}

.record-preview-link {
  margin-top: 0.5rem;
}

.record-preview-format-list {
  display: inline-block;
  list-style: none;
  margin: 0;
}

.record-preview-format {
  display: inline-block;
  padding: 0;
  margin: 0;
  color: #262626;
  margin-right: 0.5rem;
}

.record-preview-format .icon {
  color: var(--search-color-grey-600);
  margin-right: 0.2rem;
  margin-bottom: -0.1rem;
}

.record-preview-author {
  color: #262626;
}

.record-preview-format-list+.record-preview-author:before {
  content: '\00b7';
  font-weight: 600;
  margin-right: 0.4rem;
}

.record-preview-description {
  margin: 0;
  color: var(--search-color-grey-600);
}

/*
  Record Preview Fields
*/
.record-preview__field {
  color: var(--search-color-grey-600);
}

.record-preview__field-name {
  display: inline-block;
  font-weight: 400;
}

.record-preview__field-name:after {
  content: ":";
  padding-right: 0.25rem;
}

.record-preview__field-value {
  display: inline-block;
}

.landing-container {
  margin: 0 auto;
  padding: 1rem 0;
  margin-top: 1.5rem;
  border-radius: 4px;
  max-width: 30rem;
  line-height: 1.6;
}

.landing-container p {
  font-size: 1.15rem;
}

.landing-container .landing-heading-text {
  padding-bottom: 1.5rem;
  border-bottom: solid 4px #CCC;
  margin-top: 0;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
}

.landing-container .landing-heading-text b {
  color: #262626;
}

.landing-container p:not(.landing-heading-text) {
  padding-top: 1rem;
}

.landing-container a {
  text-decoration: underline;
}

.landing-icons {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.landing-icons svg {
  margin: 0 1rem;
}

.landing-icons .icon {
  width: 2.5rem;
  height: 2.5rem;
}

.landing-icons .icon g {
  color: var(--search-color-blue-400);
}

.datastore-info-container {
  align-items: flex-start;
  border-radius: 4px;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin: 0.75rem 0;
}

.datastore-info-container p {
  font-size: 0.875rem;
  margin: 0;
  line-height: 1.7;
}

.datastore-info-hide {
  padding: 0 0.25rem;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.875rem;
  line-height: 1.7;
  font-weight: 600;
}

.datastore-info-hide:hover {
  background: #E5E5E5;
}

.icon {
  width: 1rem;
  height: 1rem;
}

.icon g {
  fill: currentColor;
}

.show-all-button {
  font-size: 0.85rem;
  border-radius: 0;
  border: none;
}

.show-all-button__text {
  border-bottom: solid 1px #CCC;
}

.feedback-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.feedback-form-fieldset {
  max-width: 32rem;
}

.advanced-input {
  border: solid 1px rgba(0, 0, 0, 0.3);
}

.advanced-to-basic-link {
  margin-bottom: 1.5rem;
  display: block;
  text-align: center;
}

.advanced-to-basic-link g {
  color: var(--search-color-grey-600);
}

.advanced-input-remove-button {
  display: flex;
  white-space: nowrap;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  border: solid 1px #CCC;
  color: var(--search-color-grey-600);
  background: #F2F2F2;
  cursor: pointer;
  margin-left: 0.5rem;
}

@media (max-width: 820px) {
  .advanced-input-remove-button {
    padding: 0.5rem;
  }
}

.advanced-input-remove-button:hover {
  border-color: #818181;
}

.advanced-heading {
  margin: 1rem 0;
  font-weight: 600;
}

@media (max-width: 820px) {
  .advanced-heading {
    margin: 0.5rem 0;
  }
}

.advanced-input-container {
  display: flex;
}

@media (max-width: 820px) {
  .advanced-input-container {
    display: block;
  }

  .advanced-input-container .advanced-field-select {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.advanced-search-button-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.advanced-search-button {
  display: flex;
  align-items: center;
}

.advanced-search-button .icon {
  margin-right: 0.5rem;
}

.advanced-add-field-container {
  margin-top: 1.5rem;
}

@media (min-width: 600px) {
  .advanced-add-field-container {
    text-align: center;
  }
}

.advanced-field-select {
  margin-right: 1rem;
  min-width: 12.75rem;
}

.advanced-filters-container {
  margin-top: 2rem;
  border-top: solid 2px #E5E5E5;
}

.advanced-filter-container {
  width: 100%;
  margin-bottom: 2rem;
}

@media (min-width: 600px) {
  .advanced-filter-container {
    width: calc(50% - 1rem);
  }
}

.advanced-filter-inner-container {
  border-radius: 4px;
}

.advanced-filter-label-text {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1rem;
  border-bottom: solid 2px #CCC;
  padding-bottom: 0.5rem;
}

.advanced-filters-inner-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: -2rem;
}

.advanced-filter-fieldset select {
  width: 100%;
}

.narrow-search-to-dropdown-container + .narrow-search-to-dropdown-container {
  margin-top: 0.5rem;
}

.narrow-search-to-dropdown {
  width: 100%;
}

.advanced-fields-container {
  padding: 1.5rem;
  border-radius: 4px;
}

.advanced-input-remove-container {
  display: flex;
  width: 100%;
}

.advanced-input-remove-container>div {
  display: flex;
}

.advanced-fieldset fieldset {
  flex-direction: row;
}

@media (min-width: 641px) {
  .advanced-fieldset fieldset {
    justify-content: center;
  }
}

.trim-string-button {
  padding: 0.125rem 0.5rem;
  margin-left: 0.5rem;
}

.sorts-label-text {
  display: inline-block;
  padding-right: 0.5rem;
}

.chat-widget {
  position: fixed;
  right: 1.5%;
  bottom: 0px;
  width: 100%;
  max-width: 97.5%;
  height: 0;
  transition: all 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px 1px;
  z-index: 100;
  height: 2.75rem;
  max-height: 28rem;
  background: #F7F7F7;
  max-width: 20rem;
  border-radius: 4px 4px 0 0;
}

.chat-widget--opened {
  height: 100%;
}

.chat-widget-button {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  border: 0;
  font-size: 100%;
  text-align: left;
  height: 2.75rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
  padding: 0 1rem;
  border-radius: 4px 4px 0 0;
}

.chat-widget-button .icon {
  margin-right: 0.5rem;
}

.chat-widget-button .icon g {
  color: #05A657;
}

.chat-widget-button--small-screens {
  width: auto;
  text-decoration: underline;
  height: auto;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  border: solid 1px #CCC;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  align-items: center;
  background: #FAFAFA;
}

.chat-widget-iframe {
  width: 100%;
  height: 100%;
  border: none;
  max-height: 25rem;
  min-height: 24rem;
}

@media print {
  .chat-widget {
    display: none;
  }
}

.marc__container {
  order: 4;
}

.marc-link-container {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  order: 3;
}

.checkbox-label {
  display: flex;
  cursor: pointer;
}

.checkbox {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  gap: 0.375em;
}

.checkbox:hover {
  text-decoration: underline;
}

.checkbox+.checkbox {
  margin-top: 0.25rem;
}

.checkbox>span[class^="filter-checkbox-"] {
  margin-top: 0.125rem;
}

.institution-select-landing-container p {
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.marc__heading {
  font-size: 1rem;
}

.marc__table {
  background: white;
  font-family: monospace;
  vertical-align: top;
  font-size: 0.875rem;
  max-width: 100%;
  overflow-x: scroll;
}

.marc__table tr:nth-child(even) {
  background: #FAFAFA;
}

.marc__table td {
  vertical-align: top;
}

.marc__table td:not(:first-child):not(:last-child) {
  padding-left: 0;
}

.marc__field-name {
  color: var(--search-color-grey-600);
}

.marc__subfield {
  display: inline-block;
  padding-right: 0.5rem;
  word-break: break-word;
}

.marc__ind {
  margin-right: 1rem;
}

.marc__ind1 {
  margin-right: 1rem;
}

.browse {
  padding: 1.5rem 2rem;
  background: white;
}

.browse-heading {
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.browse-list {
  margin: 0;
  padding: 0;
}

.browse-item {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.browse-button {
  min-width: 2.5rem;
  padding: 0.75rem 1rem;
  text-align: center;
  display: flex;
  justify-content: space-around;
  line-height: 1;
  background: none;
  border: none;
  color: var(--search-color-blue-400);
  font-size: 1.15rem;
  font-weight: 600;
  border-radius: 4px;
  border: solid 1px transparent;
}

.browse-button:hover {
  background: var(--search-color-blue-200);
  border: solid 1px #CCE6FF;
  cursor: pointer;
}

.browse ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 820px) {
  .browse .nested-list {
    column-count: 2;
    column-gap: 2rem;
  }

  .browse .nested-list>li {
    display: inline-block;
  }
}

.browse .nested-list h3 {
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.browse .nested-list {
  font-size: 1.1rem;
  font-weight: 600;
}

.browse .nested-list > li {
  margin-bottom: 1.5rem;
}

.browse .nested-list li {
  padding-top: 0.5rem;
}

.browse .nested-list ul {
  font-size: 0.9em;
  font-weight: 400;
  margin-left: 1rem;
  padding-left: 1rem;
}

.browse .nested-list .browse-filter-link:hover .browse-filter-link__text {
  text-decoration: underline;
}

.browse-filter-link__count {
  color: var(--search-color-grey-600);
  padding-left: 0.25rem;
}

.recommended-resource-tag {
  vertical-align: super;
  font-size: 0.8rem;
  background: #FFCB05;
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
  margin-left: 0.3rem;
}

/*
  Get This sections
*/
.get-this-section {
  padding: 1.5rem;
  margin: 1rem 0;
}

.get-this-section-heading {
  margin-top: 0;
  font-size: 1.75rem;
}

/*
  Get This options
*/
.get-this-option {
  border: solid 2px #E5E5E5;
  margin-bottom: 1rem;
}

.get-this-option-summary {
  cursor: pointer;
  background: #FAFAFA;
  padding: 1rem 1.5rem;
}

.get-this-option-heading {
  display: inline;
}

.get-this-option-heading-text {
  color: var(--search-color-blue-400);
  font-size: 1.25rem;
  text-decoration: underline;
}

.get-this-option-subheading {
  display: block;
  color: var(--search-color-grey-600);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.get-this-option-details-container {
  padding: 1rem 1.5rem;
}

@media (min-width: 820px) {
  .get-this-option-details-container {
    display: flex;
  }

  .get-this-option-column:not(:empty) {
    flex: 1;
  }

  .get-this-option-column:not(:empty) + .get-this-option-column {
    border-left: solid 2px #CCC;
    margin-left: 1.5rem;
    padding-left: 1rem;
  }
}

/*
  Get This Policies
*/
.get-this-policies-heading {
  font-weight: 600;
}

.get-this-policies-list {
  color: var(--search-color-grey-600);
  margin-top: 0.5rem;
  margin-left: 2rem;
}

.get-this-policies-list li {
  margin-top: 0;
  padding-left: 0.25rem;
}

.get-this-policies-list a {
  text-decoration: underline;
}

/*
  Get This forms
*/
.get-this-option-label {
  color: var(--search-color-grey-600);
  margin: 0.5rem 0;
  font-weight: 600;
}

.get-this-field-group-inline {
  display: inline-block;
  margin-right: 1rem;
}

input.get-this-field-input-year {
  width: 3rem;
}

/*
  Get this FAQ
*/
.get-this-faq-heading {
  font-size: 1.5rem;
  margin-bottom: 0;
  padding-bottom: 0;
}

/*
  Holdings
*/
.get-this-holdings-container .holding-table td:first-child {
  display: none;
}

.record-container {
  position: relative;
}

.record--highlight {
  outline: solid 2px rgba(18, 109, 193, 0.6);
}

.add-to-list-checkbox-container {
  color: var(--ds-color-neutral-300);
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 1.5rem;
}

@media screen and (min-width: 820px) {
  .add-to-list-checkbox-container {
    left: 0.75rem;
    top: 1rem;
  }
}

.add-to-list-checkbox-container .checkbox {
  margin: 0;
  padding: 0 0.25rem;
  font-size: 0;
}

@media screen and (min-width: 820px) {
  .record-title-and-actions-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.record-holders-container {
  border-bottom: solid 1px var(--ds-color-neutral-100);
}

.lists-link-container {
  outline: solid 2px rgba(18, 109, 193, 0.6);
  z-index: 99;
}

@media (min-width: 600px) {
  .lists-link-container {
    position: sticky;
    top: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  }
}

.lists-link-container--collapsed {
  display: none;
}

.lists-link {
  background: #F2F9FF;
  padding: 0.5rem 1.25rem;
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.lists-link .icon {
  display: inline-block;
  margin-right: 1rem;
}

.lists-link .icon g {
  fill: var(--search-color-blue-400);
}

.lists-link p {
  display: inline-block;
  margin: 0;
  color: #262626;
}

.lists-link .lists-count-tag {
  color: white;
}

.lists-link .underline {
  color: var(--search-color-blue-400);
}

.lists-info {
  display: inline-flex;
}

.lists-count-tag {
  color: white;
  white-space: nowrap;
  margin: 0;
  background: var(--search-color-blue-500);
  padding: 0 0.2rem;
  border-radius: 4px;
}

.lists-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.lists-back-to-search-link {
  display: inline-flex;
  align-items: center;
}

.lists-back-to-search-link .icon {
  margin-right: 0.5rem;
}

.lists-section {
  background: #FAFAFA;
  border-radius: 4px;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
}

.lists-actions-heading {
  font-weight: 600;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.lists-heading {
  margin-bottom: 0;
}

.lists-actions-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 0.5rem;
  padding: 0;
  list-style: none;
}

@media (min-width: 640px) {
  .lists-actions-list {
    flex-direction: row;
    justify-content: space-between;
  }
}

.lists-actions-list li {
  display: inline-block;
  margin: 0;
  padding: 0;
  color: var(--search-color-blue-400);
}

.lists-count-summary {
  color: var(--search-color-grey-600);
}

.lists-header-info {
  display: inline-flex;
}

.lists-remove-all {
  margin-right: 1rem;
  color: var(--search-color-grey-600);
}

.lists-action-button {
  border: none;
  padding: 0.5rem 0.75rem;
  border: solid 1px transparent;
  border-radius: 4px;
  line-height: 1.2;
  margin-right: 0.25rem;
  color: #262626;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  text-align: left;
}

@media (min-width: 640px) {
  .lists-action-button {
    flex-direction: column;
    text-align: center;
  }
}

.lists-action-button:hover {
  text-decoration: underline;
}

.lists-action-button:disabled {
  background: #F2F2F2;
  color: var(--search-color-grey-600);
  cursor: not-allowed;
}

.lists-action-button:disabled:hover {
  text-decoration: none;
}

.lists-action-button .icon {
  margin-bottom: 0.2rem;
  color: var(--search-color-grey-600);
  width: 1.1rem;
  height: 1.1rem;
}

.lists-action-button--active {
  border-color: var(--search-color-blue-400);
  background: var(--search-color-blue-200);
  color: var(--search-color-blue-400);
}

.lists-action-button--active .icon {
  color: var(--search-color-blue-400);
}

.lists-action-form {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  max-width: 100%;
  align-items: flex-start;
  flex-wrap: wrap;
}

.lists-action-field-container {
  width: 100%;
}

@media (min-width: 640px) {
  .lists-action-field-container {
    max-width: 20rem;
  }
}

@media (min-width: 640px) {
  .lists-action-field-container + button {
    margin-top: 1.2rem;
  }
}

@media (min-width: 820px) {
  .lists-action-field-container + button {
    margin-top: 1.4rem;
  }
}

.lists-action {
  padding: 0;
  margin-top: 1rem;
}

.full-record__actions-container {
  background: #FAFAFA;
  border-radius: 4px;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  order: 1;
}

.full-record__actions-container .lists-action {
  margin-top: 0.5rem;
}

.full-record__date-last-indexed {
  order: 3;
}

.lists-actions__heading-tag {
  display: block;
  font-weight: 400;
}

@media (min-width: 640px) {
  .lists-actions__heading-tag {
    display: inline;
    margin-left: 1rem;
  }
}

.full-record__actions-heading {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-align: center;
}

.lists-action-alert {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  margin-top: 1rem;
}

.lists-action-alert p {
  margin: 0;
}

.error-help-section .ask-a-librarian-button {
  display: inline-block;
  margin: 0;
  padding: 0.5rem 1rem;
  background: var(--search-color-blue-400);
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  border-bottom: 3px solid var(--search-color-blue-500);
  cursor: pointer;
  text-decoration: none;
  line-height: inherit;
}

.u-break-word {
  word-wrap: break-word;
}

.u-margin-top-none {
  margin-top: 0;
}

.u-margin-bottom-none {
  margin-bottom: 0;
}

.u-margin-top-1 {
  margin-top: 1rem;
}

.u-padding-top-1 {
  padding-top: 1rem;
}

.u-margin-bottom-1 {
  margin-bottom: 1rem;
}

.u-margin-right-1 {
  margin-right: 1rem;
}

.margin-right-2 {
  margin-right: 2rem;
}

.margin-right-1 {
  margin-right: 1rem;
}

.margin-left-2 {
  margin-left: 2rem;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-y-half {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.u-display-inline-block {
  display: inline-block;
}

.underline {
  text-decoration: underline;
}

.margin-1 {
  margin: 1rem;
}

.margin-2 {
  margin: 2rem;
}

.no-margin {
  margin: 0;
}

.no-border {
  border: none;
}

.offpage {
  position: absolute;
  left: -999em;
}

.hide {
  display: none;
}

.font-small {
  font-size: 0.85rem;
}

.font-lede {
  font-size: 1.125rem;
}

.y-spacing>*:not(:last-child) {
  margin-bottom: 1rem;
}

.x-spacing>*:not(:last-child) {
  margin-right: 1rem;
}

.add-tag-heading {
  display: block;
  margin-top: 0;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.add-tag-fieldset {
  margin: 0;
  margin-bottom: 1.5rem;
}

.add-tag-fieldset label {
  margin-bottom: 0.25rem;
}

.breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.breadcrumb__item:not(:first-child):before {
  content: "/";
  margin: 0 0.5rem;
}

