.site-footer {
  background: white;
  border-top: 2px solid var(--search-color-grey-300);
  padding-bottom: 3.5rem;
  padding-top: 1.5rem;
  text-align: center;
}

.site-footer,
.site-footer a {
  color: var(--search-color-grey-600);
}

.site-footer-nav-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem 1.5rem;
  justify-content: center;
}

@media (min-width: 980px) {
  .site-footer-nav-list {
    flex-direction: row;
  }
}
