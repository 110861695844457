.shelf-browse-item.animation-out {
  animation: 0.25s ease-in-out fadeOut forwards;
}

.shelf-browse-item.animation-out-next {
  animation:
    0.25s ease-in-out fadeOut forwards,
    0.25s ease-in-out slideOutFromLeft forwards;
}

.shelf-browse-item.animation-out-previous {
  animation:
    0.25s ease-in-out fadeOut forwards,
    0.25s ease-in-out slideOutFromRight forwards;
}

.shelf-browse-item.animation-in {
  animation: 0.25s ease-in-out fadeIn forwards;
}

.shelf-browse-item.animation-in-next {
  animation:
    0.25s ease-in-out fadeIn forwards,
    0.25s ease-in-out slideInFromRight forwards;
}

.shelf-browse-item.animation-in-previous {
  animation:
    0.25s ease-in-out fadeIn forwards,
    0.25s ease-in-out slideInFromLeft forwards;
}

@media (prefers-reduced-motion) {
  .shelf-browse-item[class*="animation-"] {
    animation: none;
  }
}

.shelf-browse-item > a,
.shelf-browse-item > a > dl {
  height: 100%;
}

.shelf-browse-item > a {
  color: inherit;
  display: block;
}

.shelf-browse-item-current {
  grid-row: 3 / 1;
}

.shelf-browse-item-current > a {
  border: 4px solid var(--search-color-blue-500);
}

.shelf-browse-item dl {
  flex-direction: column;
  gap: 0.5rem;
}

.shelf-browse-item .this-item {
  align-self: baseline;
  background: var(--search-color-blue-500);
  color: white;
  font-size: 0.5rem;
  letter-spacing: 1px;
  margin-left: calc(-0.75rem - 1px);
  padding: 0.5rem 1.25rem 0.5rem 0.5rem;
  text-transform: uppercase;
  clip-path: polygon(0% 0%, 100% 0, calc(100% - 0.75rem) 50%, 100% 100%, 0% 100%);
}

.shelf-browse-item dd {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
}
.shelf-browse-item .item-term-book_cover:not(:has(div)) {
  align-self: center;
}

.shelf-browse-item .this-item,
.shelf-browse-item .item-term-title,
.shelf-browse-item .item-term-callnumber_browse {
  font-weight: 700;
}

.shelf-browse-item .item-term-published_year,
.shelf-browse-item .item-term-callnumber_browse {
  -webkit-line-clamp: 1;
}

.shelf-browse-item .item-term-title {
  color: var(--search-color-blue-400);
  text-decoration: underline;
}

.shelf-browse-item > a:hover .item-term-title {
  text-decoration-thickness: 2px;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-1rem);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(1rem);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutFromLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-1rem);
  }
}

@keyframes slideOutFromRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(1rem);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
