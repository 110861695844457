.metadata-list {
  flex-direction: column;
}

@media (min-width: 640px) {
  .metadata-list-item {
    display: grid;
    gap: 0 1rem;
    grid-template-columns: 10rem 1fr;  
  }
  
  .metadata-list-item > dd {
    grid-column: 2;
  }

  .metadata-list-item > dt {
    grid-column: 1;
    grid-row: 1 / -1;
    text-align: left;
  }
}

.record-preview .metadata-list {
  margin: 0;
  margin-top: 0.5rem;
}

.metadata-list-item > dd > ol.list__unstyled > li {
  display: inline-block;
}
