/*
  Headings
*/
h1,
.h1 {
  font-family: inherit;
  font-size: 2.5rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

b,
.strong {
  font-weight: 600;
}

.size__inherit {
  font-size: inherit;
}
