.pagination-container {
  display: block;
}
@media only screen and (min-width: 540px) {
  .pagination-container {
    align-items: baseline;
    display: flex;
  }
}
@media only screen and (min-width: 620px) {
  .pagination-container {
    display: grid;
    grid-auto-columns: 1fr auto 1fr;
    grid-column-gap: 1rem;
  }
}
.pagination-container > * {
  grid-row: 1;
}
.pagination-input-container {
  align-items: baseline;
  display: flex;
  justify-self: center;
  margin: 0.5rem 0;
}
@media only screen and (min-width: 540px) {
  .pagination-input-container {
      margin: 0;
  }
}
.pagination-next-container {
  justify-self: end;
}
.pagination-input {
  display: flex;
  margin: 0 0.5rem!important;
  padding: 0.15rem!important;
  text-align: center;
  width: 5rem!important;
}
