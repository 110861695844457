.specialists {
  overflow: hidden;
}

.specialists-heading {
  background: var(--search-color-grey-100);
  border-bottom: solid 1px var(--search-color-grey-400);
  color: var(--search-color-grey-600);
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (min-width: 980px) {
  .specialists-heading > * {
    flex-shrink: 0;
  }
}

.specialists a > .icon {
  color: var(--search-color-grey-600);
  margin-left: 0.5rem;
  width: 0.85rem;
}

@media (min-width: 980px) {
  .search-results .specialists__list {
    display: flex;
    flex-wrap: wrap;
  }
}

.specialist {
  display: grid;
  gap: 1rem;
  grid-template-areas: "image content";
  grid-template-columns: 4rem auto;
}

.specialist + .specialist,
.specialists button {
  border-top: solid 1px var(--search-color-grey-400);
}

@media (min-width: 980px) {
  .search-results .specialist {
    max-width: 33.3333%;
  }

  .search-results .specialist + .specialist {
    border-top: 0;
  }
}

.specialist img {
  border-radius: 50%;
  grid-area: image;
  height: 4rem;
  object-fit: cover;
  object-position: top right;
  width: 100%;
}

.specialist a {
  display: block;
}

.specialist a[href^="tel:+1"] {
  all: unset;
}

.specialists > button {
  width: 100%;
}
