.shelf-browse,
.shelf-browse > header {
  align-items: baseline;
}

.shelf-browse {
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "header header header"
    "carousel carousel carousel"
    ". return .";
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
  order: 3;
  padding: 1.5rem;
}

@media (min-width: 640px) {
  .shelf-browse {
    grid-template-areas:
      "header return"
      "carousel carousel";
    grid-template-columns: 1fr auto;
  }
}

.shelf-browse > header {
  grid-area: header;
  font-size: 0.875rem;
}

.shelf-browse-carousel {
  display: grid;
  gap: 1rem;
  grid-area: carousel;
  grid-template-areas: "prev list next";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
}

.shelf-browse-carousel > button svg {
  color: var(--search-color-blue-500);
}

.shelf-browse-carousel > button:disabled svg {
  color: var(--search-color-grey-500);
}

.shelf-browse-carousel > button:not(:disabled):hover svg {
  color: var(--ds-color-blue-400);
}

.shelf-browse-carousel > button:first-of-type {
  grid-area: prev;
  text-align: right;
}

.shelf-browse-carousel > button:last-of-type {
  grid-area: next;
  text-align: left;
}

.shelf-browse-items {
  display: grid;
  column-gap: 1rem;
  font-family: "noto-sans-semicondensed", sans-serif;
  font-size: 0.75rem;
  grid-area: list;
  grid-template-rows: 2.5rem auto;
  position: relative;
}

.shelf-browse-item {
  grid-row: 3 / 2;
}

.shelf-browse-carousel-return {
  grid-area: return;
}
