:root {
  --ds-color-blue-100: #f7f8f9;
  --ds-color-blue-200: #b2bec9;
  --ds-color-blue-300: #4c6781;
  --ds-color-blue-400: #00274c;
  --ds-color-blue-500: #001324;
  --ds-color-green-100: #eaf8ee;
  --ds-color-green-200: #96dbaa;
  --ds-color-green-300: #57bc75;
  --ds-color-green-400: #20a848;
  --ds-color-green-500: #198539;
  --ds-color-indigo-100: #eef1f9;
  --ds-color-indigo-200: #aab9e3;
  --ds-color-indigo-300: #7c93d4;
  --ds-color-indigo-400: #506fc5;
  --ds-color-indigo-500: #274391;
  --ds-color-maize-100: #fff9e6;
  --ds-color-maize-200: #ffea9b;
  --ds-color-maize-300: #ffda50;
  --ds-color-maize-400: #ffcb05;
  --ds-color-maize-500: #eaba02;
  --ds-color-neutral-100: #e5e9ed;
  --ds-color-neutral-200: #8a96a1;
  --ds-color-neutral-300: #637381;
  --ds-color-neutral-400: #212b36;
  --ds-color-neutral-500: #06080a;
  --ds-color-orange-100: #fff1eb;
  --ds-color-orange-200: #ffb899;
  --ds-color-orange-300: #ff8a58;
  --ds-color-orange-400: #f25f1f;
  --ds-color-orange-500: #c74e1a;
  --ds-color-pink-100: #fcebeb;
  --ds-color-pink-200: #f29d9d;
  --ds-color-pink-300: #ec6969;
  --ds-color-pink-400: #d93838;
  --ds-color-pink-500: #bf3232;
  --ds-color-teal-100: #e9f2f5;
  --ds-color-teal-200: #a7cddb;
  --ds-color-teal-300: #65a8bf;
  --ds-color-teal-400: #1d7491;
  --ds-color-teal-500: #106684;
  --search-color-blue-100: #f2f9ff;
  --search-color-blue-200: #e6f3ff;
  --search-color-blue-300: #cce6ff;
  --search-color-blue-400: #126dc1;
  --search-color-blue-500: #0c5292;
  --search-color-green-100: #e2f4eb;
  --search-color-green-200: #05a657;
  --search-color-green-300: #057c42;
  --search-color-grey-100: #fafafa;
  --search-color-grey-200: #f2f2f2;
  --search-color-grey-300: #e5e5e5;
  --search-color-grey-400: #ccc;
  --search-color-grey-500: #6e6e6e;
  --search-color-grey-600: #4e4e4e;
  --search-color-grey-700: #262626;
  --search-color-orange-100: #ffeedd;
  --search-color-orange-200: #e77504;
  --search-color-orange-300: #aa5600;
  --search-color-red-100: #ffeae7;
  --search-color-red-200: #ed5d47;
  --search-color-red-300: #c53b26;
}
