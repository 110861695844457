dialog {
  align-items: start;
  border: none;
  color: var(--search-color-grey-700);
  display: flex;
  gap: 0.5rem 1rem;
  max-width: 40rem;
  padding: 1rem;
}

@media only screen and (min-width: 640px) {
  dialog {
    padding: 2rem;
  }
}

dialog:not([open]) {
  display: none;
}

::backdrop {
  background: var(--search-color-grey-700);
  opacity: 0.75;
}

dialog > section {
  flex-grow: 1;
}

dialog > button {
  border: none!important;
  flex-shrink: 0;
  text-decoration: underline;
}
