.search-box-form {
  background: var(--search-color-blue-200);
  border-bottom: solid 2px var(--search-color-blue-300);
}

.search-box-form > .container {
  display: grid;
  flex-direction: column;
  gap: 0.75rem;
  grid-template-areas:
    "dropdown dropdown"
    "input button"
    "advanced advanced"
    "searchtip searchtip";
  grid-template-columns: 1fr auto;
  max-width: 1280px;
  padding: 0.75rem 1rem;
}

@media (min-width: 640px) {
  .search-box-form > .container {
    column-gap: 0;
    grid-template-areas:
      "dropdown input button"
      "advanced advanced advanced"
      "searchtip searchtip searchtip";
    grid-template-columns: 250px 1fr auto;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (min-width: 820px) {
  .search-box-form > .container {
    grid-template-areas:
      "dropdown input button advanced"
      "searchtip searchtip searchtip searchtip";
    grid-template-columns: 300px 1fr auto auto;
  }
}

.search-box-form > .container > .search-box-dropdown {
  background-color: var(--search-color-grey-100);
  border: solid 1px var(--search-color-blue-400);
  grid-area: dropdown;
  min-height: 2.5rem;
  padding: 0.5rem 0.75rem;
}

@media (min-width: 640px) {
  .search-box-form > .container > .search-box-dropdown {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.search-box-form > .container > input {
  border-color: var(--search-color-blue-400);
  grid-area: input;
}

@media (min-width: 640px) {
  .search-box-form > .container > input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
  }
}

.search-box-form > .container > button {
  grid-area: button;
}

@media (min-width: 640px) {
  .search-box-form > .container > button {
    margin-left: 0.75rem;
  }
}

.search-box-form > .container > .underline__hover {
  grid-area: advanced;
  text-align: center;
}

@media (min-width: 820px) {
  .search-box-form > .container > .underline__hover {
    align-self: center;
    margin-left: 0.75rem;
  }
}

.search-box-form .search-tip {
  grid-area: searchtip;
}
